<template>
  <div class="page">
    <Head :headTitle="headTitle" />
    <div class="b-container">
      <div class="con-1">
        <div class="mod1 flex flex-v">
          <div class="item item1">
            <div class="s-pub-tt flex">
              <span class="bt">学校人数统计</span>
            </div>
            <div class="chart flex-11" ref="chart1"></div>
          </div>
        </div>
        <div class="mod1 flex flex-v cenrs">
          <div class="item item2">
            <div class="s-pub-tt flex">
              <span class="bt">升学率</span>
            </div>
            <div class="chart" ref="chart2"></div>
          </div>
        </div>
        <div class="mod1 flex flex-v">
          <div class="item item3">
            <div class="s-pub-tt flex">
              <span class="bt">优秀教师配比</span>
            </div>
            <div class="chart" ref="chart4"></div>
          </div>
        </div>
      </div>
      <div class="con-c flex flex-v relative">
        <div class="mod-cen">
          <div class="mod2-map">
            <div class="school">
              <div class="blue">蓝田中学</div>
              <img
                src="../assets/images/pos.png"
                alt=""
                width="35"
                height="35"
              />
            </div>
            <!-- <div class="hosp">
              <div class="blue">新亨卫生院</div>
              <img
                src="../assets/images/pos.png"
                alt=""
                width="35"
                height="35"
              />
            </div> -->
            <!-- <img src="../assets/images/wt8.png" class="pic" alt="" /> -->
            <div class="item item1">
              <div class="txt">
                <p>
                  <span class="tit">教育机构</span>
                  <em>{{ webData.educationIns }}</em>
                  <span class="tit">所</span>
                </p>
              </div>
            </div>
            <div class="item item2">
              <div class="txt">
                <p>
                  <span class="tit">医疗机构</span>
                  <em>{{ webData.medicalIns }}</em>
                  <span class="tit">所</span>
                </p>
              </div>
            </div>
            <div
              class="item3"
              :style="indexNum == index ? 'opacity:1;' : ''"
              v-for="(item, index) in schoolList"
              :key="item.schoolName + index"
            >
              <div class="txt">
                <p>
                  <span class="tit">{{ item.schoolName }}</span>
                </p>
              </div>
            </div>
            <div
              class="item7"
              :style="indexNum == inx ? 'opacity:1;' : ''"
              v-for="(item, inx) in hospitalList"
              :key="item.hospitalName + inx"
            >
              <div class="txt">
                <p>
                  <span class="tit">{{ item.hospitalName }}</span>
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="con-2">
        <div class="mod4 flex flex-v">
          <div class="item item1">
            <div class="s-pub-tt flex">
              <span class="bt">医院就诊人数统计</span>
            </div>
            <div class="chart flex-11" ref="chart5"></div>
          </div>
        </div>
        <div class="mod4 flex flex-v cenrs">
          <div class="item item2">
            <div class="s-pub-tt flex">
              <span class="bt">优质医疗资源引入</span>
            </div>
            <div class="chart" ref="chart3"></div>
          </div>
        </div>
        <div class="mod4 flex flex-v">
          <div class="item item3">
            <div class="s-pub-tt flex">
              <span class="bt">优秀医师资质配比</span>
            </div>
            <div class="chart" ref="chart6"></div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
  
  <script>
import Head from "@/components/head_sm.vue";

let Echarts = require("echarts/lib/echarts");
import { loopShowTooltip } from "../assets/js/tooltip-auto-show-vue.js";
import axios from "axios";

export default {
  data() {
    return {
      headTitle: "",
      chart1: null,
      chart2: null,
      chart3: null,
      chart4: null,
      chart5: null,
      chart6: null,
      webData: {},
      schoolTotal: {},
      enrollmentRate: {},
      teacherData: [],
      hospitalData: [],
      nDoctor: [],
      introduce: [],
      loaded: false,
      indexNum: 0, //随机数
      hospitalList: [], //医院列表
      schoolList: [], //学校列表
    };
  },
  components: {
    Head,
  },
  computed: {
    scrollOption() {
      return {
        step: 0.3, // 数值越大速度滚动越快
        // limitMoveNum: 10, // 开始无缝滚动的数据量 this.dataList.length
        hoverStop: true, // 是否开启鼠标悬停stop
        direction: 1, // 0向下 1向上 2向左 3向右
        // openWatch: true, // 开启数据实时监控刷新dom
        singleHeight: 0, // 单步运动停止的高度(默认值0是无缝不停止的滚动) direction => 0/1
        singleWidth: 0, // 单步运动停止的宽度(默认值0是无缝不停止的滚动) direction => 2/3
        waitTime: 1000, // 单步运动停止的时间(默认值1000ms)
      };
    },
  },
  created() {},

  mounted() {
    axios.get("/10zhjy.json").then(
      (res) => {
        console.log("2222222222222222", res);
        console.log("00000000000000000");
        
      },
      (err) => {
        console.log(err.message);
      }
    );

    setInterval(() => {
      this.indexNum = Math.floor(Math.random() * (this.schoolList.length - 1));
    }, 3000);
    // [this.webData,this.schoolTotal,this.enrollmentRate,this.introduce] = await Promise.all([this.getData(),this.getSchoolData(),this.getRateData(),this.getIntroData()]);
    this.loaded = true;
    this.getData();
    // this.init1();
    this.getSchoolData();
    // this.init2();
    this.getRateData();
    // this.init3();
    this.getIntroData();
    // this.init4();
    this.getTeacherData();

    // this.init5();
    this.getHospitalData();
    // this.init6();
    this.getNdoctorData();

    window.addEventListener("resize", () => {
      this.chart1.resize();
      this.chart2.resize();
      this.chart3.resize();
      this.chart4.resize();
      this.chart5.resize();
      this.chart6.resize();
    });
  },
  beforeDestroy() {
    clearInterval();
  },
  methods: {
    getData() {
      axios
        .get("/10zhjy.json",{
          params:{
            // timestamp:new Date().getTime()
            random:Math.random()
          }
        }
        )
        .then((res) => {
          // 处理响应
          // console.log("数据:"+res.data);
          let dataObject = res.data; // 这里假设获取到的数据是一个 Object 类型的数据
          // console.log(dataObject.data);
          //
          let jsonString = JSON.stringify(dataObject); // 将 Object 类型的数据转换为字符串类型的 JSON 数据
          // console.log(jsonString) // 输出字符串类型的 JSON 数据
          let jsonObject = JSON.parse(jsonString);

          this.webData = jsonObject;
          //
          // this.schoolTotal = jsonObject.schoolTotal;
          //标题
          this.headTitle = jsonObject.bigTitle;
          this.schoolList = jsonObject.schoolList;
          this.hospitalList = jsonObject.hospitalList;
        })
        .catch((error) => {
          console.log(error.response.status);
          console.log(error.response.data);
          console.log(error.response.headers);
        });
    },

    async getSchoolData() {
      // this.$http({
      //   method:"POST",
      //   url:"http://scdata.shouxinfund.com/ls1236-MS005-zhjy.json",
      //   // data:{
      //   //   "current":this.currentPage,
      //   //   "size":this.pageSize
      //   // }
      // }).then(resp=>{
      //   console.log(resp.data);
      //   this.tableData = resp.data.records;
      //   this.total = resp.data.total;
      // })
      // axios.get('/zhjy.json').then(res => {
      //   // 处理响应
      //   // console.log("数据:"+res.data);
      //   let dataObject = res.data // 这里假设获取到的数据是一个 Object 类型的数据
      //   // console.log(dataObject.data);
      //   //
      //   let jsonString = JSON.stringify(dataObject) // 将 Object 类型的数据转换为字符串类型的 JSON 数据
      //   // console.log(jsonString) // 输出字符串类型的 JSON 数据
      //   let jsonObject = JSON.parse(jsonString)
      //   //
      //   this.schoolTotal = jsonObject.schoolTotal;
      //   //标题
      //   this.headTitle = this.webData.bigTitle;
      //
      // }).catch(error => {
      //   console.log(error.response.status)
      //   console.log(error.response.data)
      //   console.log(error.response.headers)
      // })
      const res = await axios.get("/10zhjy.json");
      let dataObject = res.data;
      let jsonString = JSON.stringify(dataObject);
      let jsonObject = JSON.parse(jsonString);
      this.schoolTotal = jsonObject.schoolTotal;

      this.init1();
    },

    init1() {
      console.log(this.schoolTotal.student);
      console.log(this.schoolTotal.teachers);

      this.chart1 = Echarts.init(this.$refs.chart1);
      let option = {
        tooltip: {
          trigger: "axis",
          backgroundColor: "rgba(0,0,0,.65)",
          textStyle: {
            fontSize: nowSize(12),
            color: "#fff",
          },
          extraCssText: "border:1px solid #3FF5FF",
        },
        toolbox: {
          show: true,
        },
        grid: {
          left: "0",
          right: nowSize(10),
          bottom: nowSize(10),
          top: nowSize(70),
          containLabel: true,
        },

        legend: {
          top: "0",
          x: "right",
          textStyle: {
            color: "#fff",
            fontSize: nowSize(24),
          },
          itemWidth: nowSize(22),
          itemHeight: nowSize(8),
          icon: "rect",
        },
        xAxis: [
          {
            type: "category",
            //boundaryGap: false,
            data: ["幼儿园", "小学", "初中", "高中"],
            // data: this.schoolTotal.level,
            axisPointer: {
              type: "",
            },
            axisLabel: {
              show: true,
              textStyle: {
                color: "#fff",
                fontSize: nowSize(24),
              },
              //rotate: '0',
              interval: 0,
            },
            axisTick: {
              show: false,
            },
            axisLine: {
              lineStyle: {
                color: "#023748", //更改坐标轴颜色
              },
            },
            splitLine: {
              show: false,
              lineStyle: {
                color: ["#edf2f8"],
                width: 1,
                type: "dashed",
              },
            },
          },
        ],
        yAxis: [
          {
            type: "value",
            name: "",
            position: "left",
            min: 0,
            //max: 60000,
            //interval: 3000,
            nameTextStyle: {
              color: "#8ea1ba",
              fontSize: nowSize(14),
              padding: [0, 0, 0, -20],
            },
            axisLabel: {
              show: true,
              textStyle: {
                color: "#fff", //更改坐标轴文字颜色
                fontSize: nowSize(24), //更改坐标轴文字大小
              },
              //formatter: '{value}%'
            },
            axisTick: {
              show: false,
            },
            axisLine: {
              show: false,
              lineStyle: {
                color: "#023748", //更改坐标轴颜色
              },
            },
            splitLine: {
              show: true,
              lineStyle: {
                color: ["#023748"],
                width: 1,
                type: "dashed",
              },
            },
          },
        ],
        series: [
          {
            name: "学生人数",
            type: "bar",
            barWidth: nowSize(16),
            data: [3200, 3720, 3700, 3720],
            // data: this.schoolTotal.student,
            itemStyle: {
              normal: {
                barBorderRadius: [nowSize(10), nowSize(10), 0, 0],
                color: new Echarts.graphic.LinearGradient(0, 1, 0, 0, [
                  {
                    offset: 0,
                    color: "#5194fd",
                  },
                  {
                    offset: 1,
                    color: "#2b6fdc",
                  },
                ]),
              },
            },
          },
          {
            name: "教师人数",
            type: "bar",
            barWidth: nowSize(16),
            data: [1558, 1116, 1539, 2279],
            // data: this.schoolTotal.teachers,
            itemStyle: {
              normal: {
                barBorderRadius: [nowSize(10), nowSize(10), 0, 0],
                color: new Echarts.graphic.LinearGradient(0, 1, 0, 0, [
                  {
                    offset: 0,
                    color: "#44f3ff",
                  },
                  {
                    offset: 1,
                    color: "#45beff",
                  },
                ]),
              },
            },
          },
        ],
      };

      this.chart1.setOption(option, true);

      this.tootipTimer1 && this.tootipTimer1.clearLoop();
      this.tootipTimer1 = 0;
      this.tootipTimer1 = loopShowTooltip(this.chart1, option, {});
    },

    async getRateData() {
      const res = await axios.get("/10zhjy.json");
      let dataObject = res.data;
      let jsonString = JSON.stringify(dataObject);
      let jsonObject = JSON.parse(jsonString);
      this.enrollmentRate = jsonObject.enrollmentRate;
      this.init2();
    },

    init2() {
      this.chart2 = Echarts.init(this.$refs.chart2);
      let option = {
        tooltip: {
          trigger: "axis",
          backgroundColor: "rgba(0,0,0,.65)",
          textStyle: {
            fontSize: nowSize(12),
            color: "#fff",
          },
          extraCssText: "border:1px solid #3FF5FF",
        },
        toolbox: {
          show: true,
        },
        grid: {
          left: "0",
          right: nowSize(10),
          bottom: nowSize(10),
          top: nowSize(70),
          containLabel: true,
        },

        legend: {
          top: "0",
          x: "right",
          textStyle: {
            color: "#fff",
            fontSize: nowSize(24),
          },
          itemWidth: nowSize(22),
          itemHeight: nowSize(8),
          icon: "rect",
        },
        xAxis: [
          {
            type: "category",
            //boundaryGap: false,
            data: ["2017年", "2018年", "2019年", "2020年", "2021年", "2022年"],
            axisPointer: {
              type: "",
            },
            axisLabel: {
              show: true,
              textStyle: {
                color: "#fff",
                fontSize: nowSize(24),
              },
              //rotate: '0',
              interval: 0,
            },
            axisTick: {
              show: false,
            },
            axisLine: {
              lineStyle: {
                color: "#023748", //更改坐标轴颜色
              },
            },
            splitLine: {
              show: false,
              lineStyle: {
                color: ["#edf2f8"],
                width: 1,
                type: "dashed",
              },
            },
          },
        ],
        yAxis: [
          {
            type: "value",
            name: "",
            position: "left",
            min: 0,
            //max: 60000,
            //interval: 3000,
            nameTextStyle: {
              color: "#8ea1ba",
              fontSize: nowSize(14),
              padding: [0, 0, 0, -20],
            },
            axisLabel: {
              show: true,
              textStyle: {
                color: "#fff", //更改坐标轴文字颜色
                fontSize: nowSize(24), //更改坐标轴文字大小
              },
              //formatter: '{value}%'
            },
            axisTick: {
              show: false,
            },
            axisLine: {
              show: false,
              lineStyle: {
                color: "#023748", //更改坐标轴颜色
              },
            },
            splitLine: {
              show: true,
              lineStyle: {
                color: ["#023748"],
                width: 1,
                type: "dashed",
              },
            },
          },
        ],

        series: [
          {
            name: "幼儿园",
            type: "bar",
            // data: [105,330,310,490,900],
            data: this.enrollmentRate.kindergarton,
            smooth: true,
            symbolSize: 3, //折线点的大小
            itemStyle: {
              normal: {
                color: "#2dd6d8", //折线点的颜色
                borderWidth: 1,
                lineStyle: {
                  color: "#2dd6d8", //折线的颜色
                },
              },
            },
          },
          {
            name: "小学",
            type: "bar",
            // data: [310,150,450,800,800],
            data: this.enrollmentRate.primary,
            smooth: true,
            symbolSize: 3, //折线点的大小
            itemStyle: {
              normal: {
                color: "#02b581", //折线点的颜色
                borderWidth: 1,
                lineStyle: {
                  color: "#02b581", //折线的颜色
                },
              },
            },
          },
          {
            name: "初中",
            type: "bar",
            // data: [99,5,205,580,560],
            data: this.enrollmentRate.junior,
            smooth: true,
            symbolSize: 3, //折线点的大小
            itemStyle: {
              normal: {
                color: "#fd9d20", //折线点的颜色
                borderWidth: 1,
                lineStyle: {
                  color: "#fd9d20", //折线的颜色
                },
              },
            },
          },
          {
            name: "高中",
            type: "bar",
            // data: [10,105,99,310,700],
            data: this.enrollmentRate.high,
            smooth: true,
            symbolSize: 3, //折线点的大小
            itemStyle: {
              normal: {
                color: "#6A5ACD", //折线点的颜色
                borderWidth: 1,
                lineStyle: {
                  color: "#6A5ACD", //折线的颜色
                },
              },
            },
          },
        ],
      };
      this.chart2.setOption(option, true);

      this.tootipTimer2 && this.tootipTimer2.clearLoop();
      this.tootipTimer2 = 0;
      this.tootipTimer2 = loopShowTooltip(this.chart2, option, {});
    },

    async getIntroData() {
      const res = await axios.get("/10zhjy.json");
      let dataObject = res.data;
      let jsonString = JSON.stringify(dataObject);
      let jsonObject = JSON.parse(jsonString);
      this.introduce = jsonObject.introduce;
      this.init3();
    },

    init3() {
      this.chart3 = Echarts.init(this.$refs.chart3);

      let chartData = [];
      chartData = this.introduce;
      // const chartData = [
      // 	{
      // 		value: 2,
      // 		name: '设备名称',
      // 	},
      // 	{
      // 		value: 3,
      // 		name: '医疗团队'
      // 	},
      // 	{
      // 		value: 8,
      // 		name: '学术水平'
      // 	},
      // 	{
      // 		value: 10,
      // 		name: '获奖荣誉'
      // 	}
      // ];
      const sum = chartData.reduce((per, cur) => per + cur.value, 0);
      let option = {
        tooltip: {
          trigger: "item",
          backgroundColor: "rgba(0,0,0,.65)",
          textStyle: {
            fontSize: nowSize(12),
            color: "#fff",
          },
          extraCssText: "border:1px solid #3FF5FF",
          axisPointer: {
            crossStyle: {
              color: "#fff",
            },
          },
          position(point, params, dom, rect, size) {},
        },
        grid: {
          left: "0",
          right: "0",
          bottom: "0",
          top: "0",
          containLabel: true,
        },
        legend: {
          x: "right",
          y: "center",
          orient: "vertial",
          icon: "rect",
          itemWidth: nowSize(24),
          itemHeight: nowSize(24),
          textStyle: {
            color: "#fff",
            fontSize: nowSize(24),
            lineHeight: nowSize(40),
          },
          formatter: (name) => {
            console.log(name);
            console.log(chartData);
            const item = chartData.find((i) => {
              return i.name === name;
            });
            const p = ((item.value / sum) * 100).toFixed(0);
            const value = item.value;
            return (
              "{name|" +
              name +
              "}" +
              "{value|" +
              value +
              "项}" +
              "{percent|" +
              p +
              "%}"
            );
          },
          textStyle: {
            rich: {
              name: {
                fontSize: nowSize(24),
                width: nowSize(230),
                height: nowSize(30),
                padding: [0, 0, 0, 5],
                color: "#fff",
              },
              value: {
                fontSize: nowSize(24),
                width: nowSize(110),
                height: nowSize(30),
                padding: [0, 0, 0, 5],
                color: "#fff",
              },
              percent: {
                fontSize: nowSize(24),
                width: nowSize(110),
                height: nowSize(30),
                padding: [0, 0, 0, 5],
                color: "#fff",
              },
            },
          },
        },
        color: ["#02b581", "#0060db", "#fd9d20", "#6A5ACD"],
        series: [
          {
            name: "",
            type: "pie",
            roseType: "area",
            radius: ["40%", "80%"],
            center: ["25%", "48%"],
            avoidLabelOverlap: false,
            hoverAnimation: true,
            label: {
              normal: {
                show: false,
              },
            },
            itemStyle: {
              borderRadius: 0,
              borderColor: "#fff",
              borderWidth: 0,
            },
            labelLine: {
              show: false,
            },
            data: chartData,
          },
        ],
      };
      this.chart3.setOption(option, true);

      this.tootipTimer3 && this.tootipTimer3.clearLoop();
      this.tootipTimer3 = 0;
      this.tootipTimer3 = loopShowTooltip(this.chart3, option, {});
    },
    // 优秀教师
    async getTeacherData() {
      const res = await axios.get("/10zhjy.json");
      let dataObject = res.data;
      let jsonString = JSON.stringify(dataObject);
      let jsonObject = JSON.parse(jsonString);
      this.teacherData = jsonObject.teacher;

      this.init4();
    },
    init4() {
      this.chart4 = Echarts.init(this.$refs.chart4);
      let chartData = [];
      chartData = this.teacherData;
      //   const chartData = [
      //     {
      //       value: 30,
      //       name: "正高级教师",
      //     },
      //     {
      //       value: 36,
      //       name: "高级教师",
      //     },
      //     {
      //       value: 20,
      //       name: "一级教师",
      //     },
      //     {
      //       value: 130,
      //       name: "二级教师",
      //     },
      //     {
      //       value: 69,
      //       name: "三级教师",
      //     },
      //   ];
      const sum = chartData.reduce((per, cur) => per + cur.value, 0);
      let option = {
        tooltip: {
          trigger: "item",
          backgroundColor: "rgba(0,0,0,.65)",
          textStyle: {
            fontSize: nowSize(12),
            color: "#fff",
          },
          extraCssText: "border:1px solid #3FF5FF",
          axisPointer: {
            crossStyle: {
              color: "#fff",
            },
          },
          position(point, params, dom, rect, size) {},
        },
        grid: {
          left: "0",
          right: "0",
          bottom: "0",
          top: "0",
          containLabel: true,
        },
        legend: {
          x: "right",
          y: "center",
          orient: "vertial",
          icon: "rect",
          itemWidth: nowSize(24),
          itemHeight: nowSize(24),
          textStyle: {
            color: "#fff",
            fontSize: nowSize(24),
            lineHeight: nowSize(40),
          },
          formatter: (name) => {
            console.log(name);
            console.log(chartData);
            const item = chartData.find((i) => {
              return i.name === name;
            });
            const p = ((item.value / sum) * 100).toFixed(0);
            const value = item.value;
            return (
              "{name|" +
              name +
              "}" +
              "{value|" +
              value +
              "人}" +
              "{percent|" +
              p +
              "%}"
            );
          },
          textStyle: {
            rich: {
              name: {
                fontSize: nowSize(24),
                width: nowSize(230),
                height: nowSize(30),
                padding: [0, 0, 0, 5],
                color: "#fff",
              },
              value: {
                fontSize: nowSize(24),
                width: nowSize(110),
                height: nowSize(30),
                padding: [0, 0, 0, 5],
                color: "#fff",
              },
              percent: {
                fontSize: nowSize(24),
                width: nowSize(110),
                height: nowSize(30),
                padding: [0, 0, 0, 5],
                color: "#fff",
              },
            },
          },
        },
        color: ["#00b7ff", "#02b581", "#fd9d20", "#6A5ACD", "#0060db"],
        series: [
          {
            name: "",
            type: "pie",
            roseType: "area",
            radius: ["40%", "80%"],
            center: ["25%", "48%"],
            avoidLabelOverlap: false,
            hoverAnimation: true,
            label: {
              normal: {
                show: false,
              },
            },
            itemStyle: {
              borderRadius: 0,
              borderColor: "#fff",
              borderWidth: 0,
            },
            labelLine: {
              show: false,
            },
            data: chartData,
          },
        ],
      };
      this.chart4.setOption(option, true);

      this.tootipTimer4 && this.tootipTimer4.clearLoop();
      this.tootipTimer4 = 0;
      this.tootipTimer4 = loopShowTooltip(this.chart4, option, {});
    },
    // 医院就诊人数
    async getHospitalData() {
      const res = await axios.get("/10zhjy.json");
      let dataObject = res.data;
      let jsonString = JSON.stringify(dataObject);
      let jsonObject = JSON.parse(jsonString);
      this.hospitalData = jsonObject.hospitalTotal;
      this.init5();
    },
    init5() {
      this.chart5 = Echarts.init(this.$refs.chart5);
      let option = {
        tooltip: {
          trigger: "axis",
          backgroundColor: "rgba(0,0,0,.65)",
          textStyle: {
            fontSize: nowSize(12),
            color: "#fff",
          },
          extraCssText: "border:1px solid #3FF5FF",
        },
        toolbox: {
          show: true,
        },
        grid: {
          left: "0",
          right: nowSize(10),
          bottom: nowSize(10),
          top: nowSize(70),
          containLabel: true,
        },

        legend: {
          top: "0",
          x: "right",
          textStyle: {
            color: "#fff",
            fontSize: nowSize(24),
          },
          itemWidth: nowSize(22),
          itemHeight: nowSize(20),
          icon: "rect",
        },
        xAxis: [
          {
            type: "category",
            //boundaryGap: false,
            // data: this.hospitalData.level,
            data: [
              "1月",
              "2月",
              "3月",
              "4月",
              "5月",
              "6月",
              "7月",
              "8月",
              "9月",
              "10月",
              "11月",
              "12月",
            ],
            axisPointer: {
              type: "",
            },
            axisLabel: {
              show: true,
              textStyle: {
                color: "#fff",
                fontSize: nowSize(24),
              },
              //rotate: '0',
              interval: 0,
            },
            axisTick: {
              show: false,
            },
            axisLine: {
              lineStyle: {
                color: "#023748", //更改坐标轴颜色
              },
            },
            splitLine: {
              show: false,
              lineStyle: {
                color: ["#edf2f8"],
                width: 1,
                type: "dashed",
              },
            },
          },
        ],
        yAxis: [
          {
            type: "value",
            name: "",
            position: "left",
            min: 0,
            //max: 60000,
            //interval: 3000,
            nameTextStyle: {
              color: "#8ea1ba",
              fontSize: nowSize(14),
              padding: [0, 0, 0, -20],
            },
            axisLabel: {
              show: true,
              textStyle: {
                color: "#fff", //更改坐标轴文字颜色
                fontSize: nowSize(24), //更改坐标轴文字大小
              },
              //formatter: '{value}%'
            },
            axisTick: {
              show: false,
            },
            axisLine: {
              show: false,
              lineStyle: {
                color: "#023748", //更改坐标轴颜色
              },
            },
            splitLine: {
              show: true,
              lineStyle: {
                color: ["#023748"],
                width: 1,
                type: "dashed",
              },
            },
          },
        ],

        series: [
          {
            name: "普通就诊",
            type: "bar",
            barWidth: nowSize(16),
            // data: this.hospitalData.common,
            data: [125, 156, 142, 131, 56, 89, 78, 124, 178, 95, 45, 137],
            itemStyle: {
              normal: {
                barBorderRadius: [nowSize(10), nowSize(10), 0, 0],
                color: new Echarts.graphic.LinearGradient(0, 1, 0, 0, [
                  {
                    offset: 0,
                    color: "#5194fd",
                  },
                  {
                    offset: 1,
                    color: "#2b6fdc",
                  },
                ]),
              },
            },
          },
          {
            name: "急诊",
            type: "bar",
            barWidth: nowSize(16),
            // data: this.hospitalData.emergency,
            data: [56, 35, 78, 56, 46, 89, 79, 156, 56, 71, 23, 78],
            itemStyle: {
              normal: {
                barBorderRadius: [nowSize(10), nowSize(10), 0, 0],
                color: new Echarts.graphic.LinearGradient(0, 1, 0, 0, [
                  {
                    offset: 0,
                    color: "#44f3ff",
                  },
                  {
                    offset: 1,
                    color: "#45beff",
                  },
                ]),
              },
            },
          },
        ],
      };
      this.chart5.setOption(option, true);

      this.tootipTimer5 && this.tootipTimer5.clearLoop();
      this.tootipTimer5 = 0;
      this.tootipTimer5 = loopShowTooltip(this.chart5, option, {});
    },
    // 优秀医师
    async getNdoctorData() {
      const res = await axios.get("/10zhjy.json");
      let dataObject = res.data;
      let jsonString = JSON.stringify(dataObject);
      let jsonObject = JSON.parse(jsonString);
      this.nDoctor = jsonObject.doctor;
      this.init6();
    },
    init6() {
      this.chart6 = Echarts.init(this.$refs.chart6);
      let chartData = [];
      chartData = this.nDoctor;
      //   const chartData = [
      //     {
      //       value: 30,
      //       name: "高级医师",
      //     },
      //     {
      //       value: 36,
      //       name: "主任医师",
      //     },
      //     {
      //       value: 20,
      //       name: "教授",
      //     },
      //     {
      //       value: 130,
      //       name: "普通医师",
      //     },
      //     {
      //       value: 69,
      //       name: "副主任医师",
      //     },
      //   ];
      const sum = chartData.reduce((per, cur) => per + cur.value, 0);
      let option = {
        tooltip: {
          trigger: "item",
          backgroundColor: "rgba(0,0,0,.65)",
          textStyle: {
            fontSize: nowSize(12),
            color: "#fff",
          },
          extraCssText: "border:1px solid #3FF5FF",
          axisPointer: {
            crossStyle: {
              color: "#fff",
            },
          },
          position(point, params, dom, rect, size) {},
        },
        grid: {
          left: "0",
          right: "0",
          bottom: "0",
          top: "0",
          containLabel: true,
        },
        legend: {
          x: "right",
          y: "center",
          orient: "vertial",
          icon: "rect",
          itemWidth: nowSize(24),
          itemHeight: nowSize(24),
          textStyle: {
            color: "#fff",
            fontSize: nowSize(24),
            lineHeight: nowSize(40),
          },
          formatter: (name) => {
            console.log(name);
            console.log(chartData);
            const item = chartData.find((i) => {
              return i.name === name;
            });
            const p = ((item.value / sum) * 100).toFixed(0);
            const value = item.value;
            return (
              "{name|" +
              name +
              "}" +
              "{value|" +
              value +
              "人}" +
              "{percent|" +
              p +
              "%}"
            );
          },
          textStyle: {
            rich: {
              name: {
                fontSize: nowSize(24),
                width: nowSize(230),
                height: nowSize(30),
                padding: [0, 0, 0, 5],
                color: "#fff",
              },
              value: {
                fontSize: nowSize(24),
                width: nowSize(110),
                height: nowSize(30),
                padding: [0, 0, 0, 5],
                color: "#fff",
              },
              percent: {
                fontSize: nowSize(24),
                width: nowSize(110),
                height: nowSize(30),
                padding: [0, 0, 0, 5],
                color: "#fff",
              },
            },
          },
        },
        color: ["#00b7ff", "#02b581", "#fd9d20", "#6A5ACD", "#0060db"],
        series: [
          {
            name: "",
            type: "pie",
            roseType: "area",
            radius: ["40%", "80%"],
            center: ["25%", "48%"],
            avoidLabelOverlap: false,
            hoverAnimation: true,
            label: {
              normal: {
                show: false,
              },
            },
            itemStyle: {
              borderRadius: 0,
              borderColor: "#fff",
              borderWidth: 0,
            },
            labelLine: {
              show: false,
            },
            data: chartData,
          },
        ],
      };
      this.chart6.setOption(option, true);

      this.tootipTimer6 && this.tootipTimer6.clearLoop();
      this.tootipTimer6 = 0;
      this.tootipTimer6 = loopShowTooltip(this.chart6, option, {});
    },
  },
};
</script>
  
  <style scoped>
.page {
  /* background: url(../assets/images/map3.png) no-repeat center; */
  /* background-size: 100% 100%; */
  background: url(https://home/ResImg/xh_map1.jpg) no-repeat center;
  background-size: cover;
  background-color: #0a224b;
}

.mod1 {
  height: 100%;
  margin-bottom: 10px;
}
.mod2 {
  height: 40%;
  margin-bottom: 10px;
}
.mod3 {
  height: calc(30% - 20px);
}
.mod4 {
  /* height: calc(30% - 20px); */
  margin-bottom: 10px;
  flex: 1;
}
.mod5 {
  height: 45%;
}
.mod7 {
  height: calc(27% - 165px);
}
.con-2 .mod4.mr {
  margin: 10%;
}

.mod1-di {
  display: flex;
  justify-content: space-between;
}

.mod1 .item {
  width: 25%;
  padding: 10px;
  border-radius: 10px;
  border-style: solid;
  border-width: length 10px;
  border-color: #fff;
  background: linear-gradient(
    to top right,
    rgba(76, 117, 167, 0.74),
    rgba(85, 85, 85, 0.74)
  );
  opacity: 0.9;
  background-color: #ffffff;
  position: absolute;
  height: 30%;
}
.mod1 .item .txt {
  font-size: 30px;
  color: #45f5ff;
  line-height: 50px;
  padding-top: 7px;
}
.mod1 .item .txt p {
  display: flex;
  justify-content: space-between;
  margin-top: 5px;
}
.mod1 .item .tit {
  color: #fff;
}
.mod1 .item .txt * {
  font-family: "YouSheBiaoTiHei";
}
.mod1 .item .chart {
  height: calc(100% - 100px);
  flex: 1;
}

.mod1 .item1 {
  left: 50px;
}
.mod1 .item2 {
  height: 25%;
  left: 50px;
}
.mod1 .item3 {
  left: 50px;
  height: 25%;
}

.mod1-gk {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 20%;
}
.mod1-gk li {
  display: flex;
  align-items: center;
  width: 33%;
}
.mod1-gk .icon {
  width: 150px;
  height: 150px;
}
.mod1-gk .info {
  flex: 1;
  overflow: hidden;
}
.mod1-gk .tit {
  font-size: 20px;
  color: #fff;
}
.mod1-gk .num {
  font-size: 26px;
  color: #fff;
  font-family: "YouSheBiaoTiHei";
}

.mod1-b {
  display: flex;
  justify-content: space-between;
}
.mod1-b .b-smtit {
  margin-bottom: 0;
}
.mod1-b {
  display: flex;
  justify-content: space-between;
  margin-top: 10px;
}
.mod1-b .col {
  display: flex;
  flex-direction: column;
  height: 100%;
}
.mod1-b .col1 {
  width: 40%;
  height: 80%;
}
.mod1-b .col2 {
  width: 60%;
  height: 70%;
}
.mod1-b .col3 {
  width: 28%;
  height: 220px;
}
.mod1-b .chart {
  height: calc(100% - 100px);
  flex: 1;
}

.mod1-b .grp {
  margin-top: 40px;
}
.mod1-b .grp .num {
  font-size: 24px;
  font-family: "YouSheBiaoTiHei";
}
.mod1-b .grp .fz {
  font-size: 18px;
  color: #fff;
}
.mod1-b .tip {
  font-size: 20px;
  color: #fff;
  margin-top: 30px;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
}
.mod1-b .tip .star {
  width: 18px;
  display: inline-block;
  vertical-align: middle;
  margin-left: 5px;
}
.mod2-b {
  display: flex;
  justify-content: space-between;
  margin-top: 10px;
}
.mod1-b .tip .wz {
  font-size: 20px;
  color: red;
  margin-left: 5px;
}

.mod2-b .col {
  display: flex;
  flex-direction: column;
  height: 100%;
}
.mod2-b .col1 {
  width: 40%;
  height: 40%;
}
.mod2-b .col2 {
  width: 50%;
}
.mod2-b .col3 {
  width: 27%;
  padding-left: 1%;
}
.mod2-b .chart {
  height: calc(100% - 100px);
  flex: 1;
}

.mod2-bot {
  position: absolute;
  width: calc(100% + 70px);
  left: -35px;
  bottom: 50px;
  display: flex;
  justify-content: space-between;
}
.mod2-bot .b-smtit {
  margin-bottom: 10px;
}
.mod2-bot .col {
  background-color: rgba(0, 48, 104, 0.21);
  border-radius: 10px;
  height: 300px;
  padding: 15px;
}
.mod2-bot .col1 {
  width: 32%;
}
.mod2-bot .col2 {
  width: 34%;
}
.mod2-bot .col3 {
  width: 34%;
}
.mod2-bot .chart {
  height: calc(100% - 50px);
}

.mod2-map {
  position: absolute;
  width: calc(100% + 300px);
  height: 100%;
  left: 0;
  top: 0;
  z-index: 1;
  margin-left: -150px;
}
.mod2-map .school {
  position: absolute;
  top: 59%;
  left: 30%;
}
.mod2-map .school .blue,
.mod2-map .hosp .blue {
  color: #fff;
  opacity: 0.9;
}
.mod2-map .item,
.mod2-map .item3,
.mod2-map .item7 {
  width: 400px;
  height: 100px;
  padding: 20px;
  border-radius: 10px;
  border-style: solid;
  border-width: length 10px;
  border-color: #fff;
  background: linear-gradient(
    to top right,
    rgba(76, 117, 167, 0.74),
    rgba(85, 85, 85, 0.74)
  );
  opacity: 0.9;
  background-color: #ffffff;
  position: absolute;
}
.mod2-map .item .txt,
.mod2-map .item3 .txt,
.mod2-map .item7 .txt {
  font-size: 50px;
  color: #45f5ff;
}
.mod2-map .item .txt p,
.mod2-map .item3 .txt p,
.mod2-map .item7 .txt p {
  display: flex;
  justify-content: space-between;
  margin-top: 5px;
}
.mod2-map .item .tit,
.mod2-map .item3 .tit,
.mod2-map .item7 .tit {
  color: #fff;
  font-size: 40px;
}
.mod2-map .item .txt * {
  font-family: "YouSheBiaoTiHei";
}

.mod2-map .item1 {
  left: 24%;
  top: 1px;
}
.mod2-map .item2 {
  right: 24%;
  top: 1px;
}
.mod2-map .item3 {
  left: 56%;
  top: 50%;
  width: 500px;
  height: 100px;
  opacity: 0;
  text-align: center;
}
.mod2-map .item3:nth-child(2) {
  right: 50%;
  top: 45%;
  width: 500px;
  height: 100px;
  opacity: 0;
}
.mod2-map .item3:nth-child(3) {
  left: 20%;
  top: 70%;
  width: 500px;
  height: 100px;
  opacity: 0;
}
.mod2-map .item3:nth-child(4) {
  right: 50%;
  top: 60%;
  width: 500px;
  height: 100px;
  opacity: 0;
}
.mod2-map .item3:nth-child(5) {
  right: 24%;
  top: 10%;
  width: 500px;
  height: 100px;
  opacity: 0;
  text-align: center;
}
/* .mod2-map .item4 {
  right: 34%;
  bottom: 38%;
  width: 300px;
  height: 100px;
}
.mod2-map .item5 {
  left: 30%;
  top: 48%;
  width: 300px;
  height: 100px;
}
.mod2-map .item6 {
  right: 27%;
  top: 31%;
  width: 300px;
  height: 100px;
} */
.mod2-map .item7 {
  right: 5%;
  top: 30%;
  width: 500px;
  height: 100px;
  opacity: 0;
  text-align: center;
}
.mod2-map .item7:nth-child(8) {
  right: 20%;
  top: 40%;
  width: 500px;
  height: 100px;
  opacity: 0;
}
.mod2-map .item7:nth-child(9) {
  left: 30%;
  top: 40%;
  width: 500px;
  height: 100px;
  opacity: 0;
}
.mod2-map .item7:nth-child(10) {
  left: 50%;
  top: 30%;
  width: 500px;
  height: 100px;
  opacity: 0;
}

/* .mod2-map .item8 {
  right: 20%;
  top: 40%;
  width: 300px;
  height: 100px;
}
.mod2-map .item9 {
  right: 20%;
  top: 40%;
  width: 300px;
  height: 100px;
}
.mod2-map .item10 {
  right: 20%;
  top: 40%;
  width: 300px;
  height: 100px;
} */

.mod3 {
  width: calc(100% + 50px);
}
.mod3 .list {
  display: flex;
  justify-content: space-between;
}
.mod3 .list .item {
  display: flex;
  align-items: center;
  width: 50%;
}
.mod3 .list .item1 {
  display: flex;
  align-items: end;
  width: 30%;
}
.mod3 .list .item .imgbox {
  height: 100%;
  width: 100%;
}
.mod3 .list .item1 .imgbox {
  height: 70%;
  width: 130%;
}
.mod3 .list .imgbox {
  width: 210px;
  height: 135px;
  border: 4px solid #041f48;
  border-radius: 8px;
  margin-right: 10px;
}
.mod3 .list .info {
  flex: 1;
  overflow: hidden;
}
.mod3 .list .title {
  font-size: 21px;
  color: #fff;
  margin-bottom: 5px;
}
.mod3 .list .bot {
  display: flex;
  align-items: center;
}
.mod3 .list .num {
  font-size: 24px;
  color: #45f5ff;
  font-family: "YouSheBiaoTiHei";
}
.mod3 .list .level {
  font-size: 22px;
  color: #00ffb5;
  height: 34px;
  line-height: 34px;
  border: 1px solid #00ffb5;
  border-radius: 6px;
  margin-left: 10px;
  display: inline-block;
  vertical-align: middle;
  padding: 0 8px;
  font-family: "YouSheBiaoTiHei";
}

.mod4 .item {
  width: 25%;
  padding: 10px;
  border-radius: 10px;
  border-style: solid;
  border-width: length 10px;
  border-color: #fff;
  background: linear-gradient(
    to top right,
    rgba(76, 117, 167, 0.74),
    rgba(85, 85, 85, 0.74)
  );
  position: absolute;
  height: 30%;
}
.mod4 .item .txt {
  font-size: 30px;
  color: #45f5ff;
  line-height: 50px;
  padding-top: 7px;
}
.mod4 .item .txt p {
  display: flex;
  justify-content: space-between;
  margin-top: 5px;
}
.mod4 .item .tit {
  color: #fff;
}
.mod4 .item .txt * {
  font-family: "YouSheBiaoTiHei";
}
.mod4 .item .chart {
  height: calc(100% - 100px);
  flex: 1;
}

.mod4 .item1 {
  right: 50px;
}
.mod4 .item2 {
  right: 50px;
  height: 25%;
}
.mod4 .item3 {
  right: 50px;
  height: 25%;
}

.mod4-b {
  display: flex;
  justify-content: space-between;
}
.mod4-b .b-smtit {
  margin-bottom: 0;
}
.mod4-b {
  display: flex;
  justify-content: space-between;
  margin-top: 10px;
}
.mod4-b .col {
  display: flex;
  flex-direction: column;
  height: 100%;
}
.mod4-b .col1 {
  width: 40%;
  height: 80%;
}
.mod4-b .col2 {
  width: 55%;
  height: 70%;
}
.mod4-b .col3 {
  width: 28%;
  height: 220px;
}
.mod4-b .chart {
  height: calc(100% - 100px);
  flex: 1;
}
.mod4-b .bar-list .row {
  margin-bottom: 15px;
  padding: 15px;
}
.mod4-b .bar-list .row .num {
  padding: 10px;
  font-size: 35px;
}

.mod5-gk {
  min-height: 10vh;
}
.mod5-gk {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.mod5-gk li {
  display: flex;
  align-items: center;
}
.mod5-gk .icon {
  width: 100px;
}
.mod5-gk .info {
  flex: 1;
  overflow: hidden;
}
.mod5-gk .tit {
  font-size: 20px;
  color: #fff;
}
.mod5-gk .num {
  font-size: 26px;
  color: #fff;
  font-family: "YouSheBiaoTiHei";
}

.mod5-sl {
  display: flex;
  justify-content: space-between;
  min-height: 20vh;
}
.mod5-sl .col1 {
  width: 40%;
}
.mod5-sl .col2 {
  width: 34%;
}
.mod5-sl .col3 {
  width: 50%;
}

.mod5-sl .hang {
  font-size: 30px;
  color: #fff;
  line-height: 24px;
  padding: 25px;
}
.mod5-sl .hang span {
  font-size: 30px;
  font-family: "YouSheBiaoTiHei";
  margin-left: 15px;
}
.mod5-sl .hang:nth-child(2) span {
  color: #ffc55a;
}
.mod5-sl .hang:nth-child(3) span {
  color: #90ffad;
}
.mod5-sl .hang:nth-child(4) span {
  color: #14c1fd;
}

.mod5-sl .bar-list .row {
  margin-bottom: 15px;
}

.mod5-sl .chart {
  height: calc(100% - 40px);
}

.con-c {
  position: relative;
}

.cen-top.b {
  position: absolute;
  width: 100%;
  left: 0;
  bottom: 30px;
  padding: 0 5%;
}
.con-1 {
  width: 33%;
  display: flex;
  flex-direction: column;
}

.con-2 {
  width: 33%;
  display: flex;
  flex-direction: column;
}

.cm {
  color: #ffd105;
}
.cy {
  color: #015aff;
}
.cenrs {
  justify-content: center;
}
.mod2-map .item3 .txt p {
  justify-content: center;
}
.mod2-map .item7 .txt p {
  justify-content: center;
}
</style>
  